import React from "react";

import Layout from "~components/base/layout";
import SEO from "~components/base/seo";

import Accordion from "~components/accordion/accordion";
import Products from "~components/products/products";
import "~styles/pages/services/_studio-rental.scss";
import TypeAnimation from "react-type-animation";

const StudioRentalPage = () => (
  <Layout>
    <SEO title="Studio Rental" />
    <section className="page-heading">
      <div className="container">
        <TypeAnimation
          cursor={false}
          sequence={["Studio Rental", 500]}
          className={"title"}
          wrapper="h1"
        />
      </div>
    </section>
    <section className="">
      <div className="container">
        <Accordion bodyPadding={false}>
          <div label="1 hour studio" subtitle="$150-$450 per hour">
            <p>
              We have the studio space in our Vancouver HQ (flexible space that
              can be organized to fit a variety of looks), all of the equipment
              a production team could ever dream of, and a production team that
              can help you produce your content or live event, so you can focus
              on what you do best – share your insights and thoughts with the
              world.
            </p>
            <Products type="slim">
              <div
                title="$150 per hour"
                id="studio-1h-150"
                price="150"
                subtitle="(BYOE)"
              ></div>
              <div
                title="$300 per hour"
                price="300"
                id="studio-1h-300"
                subtitle="(Leverage our cameras, lights and audio equipment)"
              ></div>
              <div
                title="$450 per hour"
                price="450"
                id="studio-1h-450"
                subtitle="(Full production support)"
              ></div>
            </Products>
          </div>
          <div label="Half day studio" subtitle="$450-$1,600">
            <p>4 hours + 1 additional hour of setup/teardown/organizing</p>
            <Products type="slim">
              <div title="$450" id="studio-hd-450" subtitle="(BYOE)"></div>
              <div
                title="$1,100"
                id="studio-hd-1100"
                subtitle="(Leverage our cameras, lights and audio equipment)"
              ></div>
              <div
                title="$1,600"
                id="studio-hd-1600-fps"
                subtitle="(Full Production Support)"
              ></div>
            </Products>
          </div>
          <div label="Full day studio" subtitle="$800-$3,000">
            <p>8 hours + 1 additional hour of setup/teardown/organizing</p>
            <Products type="slim">
              <div title="$800" id="studio-fd-800" subtitle="(BYOE)"></div>
              <div
                title="$2,000"
                id="studio-fd-2000"
                subtitle="(Leverage our cameras, lights and audio equipment)"
              ></div>
              <div
                title="$3,000"
                id="studio-fd-3000"
                subtitle="(Full Production Support)"
              ></div>
            </Products>
          </div>
        </Accordion>
      </div>
    </section>
  </Layout>
);

export default StudioRentalPage;
